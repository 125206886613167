const routerPath = {
  MAIN: '/',

  BLOG: '/blog',
  
  NOTICE_LIST: '/blog/notice',
  NOTICE_DETAIL: '/blog/notice/detail/:id',

  POST_LIST: '/blog/post',
  POST_DETAIL: '/blog/post/detail/:id',

  COMPANY: '/company',

  HELP: '/help',
};

export default routerPath;