const state = {
  isToastPopup: false,
  toastData: {
    toastMsg: null,
    toastType: '' // success, error
  },
}

const getters = {
	getIsToastPopup: (state) => state.isToastPopup,
	getIsToastData: (state) => state.toastData,
}

const actions = {
  fetchToastStart({ commit }, params) {
    commit('START_TOAST_MUTATION', params);
  },
  fetchToastEnd({ commit }, params) {
    commit('END_TOAST_MUTATION');
  },
}

const mutations = {
  START_TOAST_MUTATION(state, payload) {
    if(payload && payload.toastMsg){
      state.toastData.toastMsg = payload.toastMsg;
      state.toastData.toastType = payload.toastType;
      state.isToastPopup = true;
    }
  },
  END_TOAST_MUTATION(state, payload) {
    state.isToastPopup = false;
    state.toastData.toastMsg = null;
    state.toastData.toastType = '';
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}