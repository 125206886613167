const apiPath = {
  SURVEY:"/api/survey/v1", // 출시알림 등록
  LETTER_LATEST:"/api/lemonletter/v1/lemonletter/latest", // 최신 레몬레터
  // 공지&amp;이벤트
  NOTICE_LIST: '/api/notice/v1/notices',
  NOTICE_DETAIL:'/api/notice/v1/notice/%s',
  // 포스트
  POST_LIST: '/api/post/v1/posts',
  POST_DETAIL:'/api/post/v1/post/%s',
  POST_HOT_LIST: '/api/post/v1/post/hots',
  // FAQ
  FAQ_LIST: '/api/faq/v1/faqs', // 목록
  FAQ_CATEGORY: '/api/faq/v1/categories', // 카테고리 목록
  FAQ_TOP_LIST: '/api/faq/v1/faq/top', // top10 목록
  FAQ_CATEGORY_LIST: '/api/faq/v1/faqs/category/%s', // 카테고리별 목록 [id]
  FAQ_SEARCH: '/api/faq/v1/faqs/search/%s', // 검색 [keyword]
};
export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};