<template>
  <div>
    <router-view></router-view>
    <ToastPopup
      v-if="getIsToastPopup"
      :getIsToastPopup="getIsToastPopup"
      :getIsToastData="getIsToastData" />
    <DropBoxSelect v-if="getIsSelectDropBox"/>
  </div>
</template>

<script>
import ToastPopup from '@/components/layout/popup/ToastPopup';
import DropBoxSelect from '@/components/layout/popup/DropBoxSelect';
import { mapGetters, mapActions } from 'vuex';
import { breakPoint } from '@/constants/breakPoint';

export default {
  name: 'App',
  components:{
    ToastPopup,
    DropBoxSelect
  },
  beforeMount(){
    // this.setNaverNaId();
    this.onResize();
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.onResize);
  },
  computed:{
    ...mapGetters('commonToast', [ 'getIsToastPopup','getIsToastData' ]),
    ...mapGetters('commonDropBox', [ 'getIsSelectDropBox','getIsDateDropBox', 'getIsTimeDropBox']),
  },
  methods:{
    ...mapActions('commonViewport', [ 'fetchViewPort' ]),
    // setNaverNaId(){
    //   if(!wcs_add) var wcs_add = {};
    //   wcs_add["wa"] = this.$config.naverNaId;
    // },
    onResize(){
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isMobile = width < breakPoint.mb;
      const footEl = document.getElementById('firfinFoot');
      const footHeight = footEl ? footEl.offsetHeight : ( isMobile ? 724 : 500 );
      // console.log({ width: width, height: height, footHeight: footHeight, isMobile: isMobile })
      this.fetchViewPort({ width: width, height: height, footHeight: footHeight, isMobile: isMobile });
    },
  },
}
</script>
