const state = {
  windowWidth: 0,
  windowHeight: 0,
  footHeight: 0,
  isMobile: false,
}

const getters = {
	getWindowWidth: (state) => state.windowWidth,
	getWindowHeight: (state) => state.windowHeight,
	getFooterHeight: (state) => state.footHeight,
	getIsMobile: (state) => state.isMobile,
}

const actions = {
  fetchViewPort({ commit }, params) {
    commit('SET_VIEWPOR_MUTATION', params)
  },
}

const mutations = {
  SET_VIEWPOR_MUTATION(state, payload) {
    if(payload.width) state.windowWidth = payload.width;
    if(payload.height) state.windowHeight = payload.height;
    if(payload.footHeight) state.footHeight = payload.footHeight;
    if(payload.isMobile != undefined) state.isMobile = payload.isMobile;
  },
}

export default {
	namespaced: true,
	state,
  getters,
	actions,
	mutations,
}