import Vue from 'vue';
import Router from 'vue-router';
// import menuId from '@/constants/menuId';
import routerPath from './routerPath';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: routerPath.MAIN,
      name: 'Main',
      meta: { 
        menuId: 'MAIN',
        pageTitle:'메인',
      },
      component: () => import('@/views/main/view/Main.vue'),
    },
    {
      path: routerPath.BLOG,
      name: 'Blog',
      meta: { 
        menuId: 'BLOG',
        pageTitle:'블로그메인',
      },
      component: () => import('@/views/blog/view/Blog.vue'),
    },
    {
      path: routerPath.NOTICE_LIST,
      name: 'NoticeList',
      meta: { 
        menuId: 'BLOG',
        subMenuId: 'NOTICE',
        pageTitle:'공지&amp;이벤트 목록',
      },
      component: () => import('@/views/blog/view/notice/NoticeList.vue'),
    },
    {
      path: routerPath.NOTICE_DETAIL,
      name: 'NoticeDetail',
      meta: { 
        menuId: 'BLOG',
        subMenuId: 'NOTICE',
        pageTitle:'공지&amp;이벤트 상세',
      },
      component: () => import('@/views/blog/view/notice/NoticeDetail.vue'),
    },
    {
      path: routerPath.POST_LIST,
      name: 'PostList',
      meta: { 
        menuId: 'BLOG',
        subMenuId: 'POST',
        pageTitle:'블로그 목록',
      },
      component: () => import('@/views/blog/view/post/PostList.vue'),
    },
    {
      path: routerPath.POST_DETAIL,
      name: 'PostDetail',
      meta: { 
        menuId: 'BLOG',
        subMenuId: 'POST',
        pageTitle:'블로그 상세',
      },
      component: () => import('@/views/blog/view/post/PostDetail.vue'),
    },
    {
      path: routerPath.COMPANY,
      name: 'Company',
      meta: { 
        menuId: 'COMPANY',
        pageTitle:'회사소개',
      },
      component: () => import('@/views/company/view/Company.vue'),
    },
    {
      path: routerPath.HELP,
      name: 'Help',
      meta: { 
        menuId: 'HELP',
        pageTitle:'고객센터',
      },
      component: () => import('@/views/help/view/Help.vue'),
    },
    { path: '*', redirect: routerPath.MAIN },
  ]
});

export default router
