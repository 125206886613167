<template>
  <div
    :class="['toast_comm', `toast_${getIsToastData.toastType}`, { active : getIsToastPopup && isActive && getIsToastData.toastMsg && getIsToastData.toastType }]"
     ref="toastComm">
    <div class="inner_toast">
      <p class="desc_message" v-html="getIsToastData.toastMsg"></p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ToastPopup',
  props:{
    getIsToastPopup: Boolean,
    getIsToastData: Object,
  },
  data() {
    return {
      timeoutID0: null,
      timeoutID1: null,
      timeoutID2: null,
      isActive: false
    }
  },
  mounted(){
    this.toastMotion();
  },
  beforeUnmount() {
    if (this.timeoutID0) clearTimeout(this.timeoutID0);
    if (this.timeoutID1) clearTimeout(this.timeoutID1);
    if (this.timeoutID2) clearTimeout(this.timeoutID2);
  },
  methods:{
    ...mapActions('commonToast', [ 'fetchToastEnd' ]),
    toastMotion(){
      const _this = this;
      this.isActive = true;

      // this.timeoutID0 = setTimeout(function(){
      //   console.log('active')
      //   _this.isActive = true;
      //   // _this.$refs.toastComm.classList.add('active');
      //   this.timeoutID0 = null;
      // }, 100);
      
      this.timeoutID1 = setTimeout(function(){
        _this.isActive = false;
        // _this.$refs.toastComm.classList.remove('active');
        this.timeoutID1 = null;
      }, 2000);
      
      this.timeoutID2 = setTimeout(function(){
        // _this.$emit("onComplete");
        _this.onCompleteToast();
        this.timeoutID2 = null;
      }, 2300);
    },
    onCompleteToast() {
      this.fetchToastEnd();
    },
  }
}
</script>
<style scoped>
.toast_comm{position:fixed;top:-100px;left:0;right:0;z-index:9999;width:100%;padding:0 20px;box-sizing:border-box;text-align:center;opacity:1;transition:top .3s ease}
.toast_comm .inner_toast{display:inline-block;width:100%;max-width:620px;padding:24px 16px;border-radius:4px;vertical-align:top;box-sizing:border-box;background-color:#fff;text-align:left;transition:background-color 0s}
.toast_success .inner_toast{background-color:#579BF5}
.toast_error .inner_toast{background-color:#E23C3C}

.toast_comm .inner_toast .desc_message{font-weight:500;font-size:16px;line-height:24px;color:#fff;word-wrap:break-word;word-break:keep-all}

.toast_comm.active{top:48px}

/* 모바일 */
@media all and (max-width:1199px){
  .toast_comm .inner_toast .desc_message{font-size:14px;line-height:22px}
  .toast_comm.active{top:16px}
}
</style>