export const autoValid = (value, valueType, referenceValue, unReferenceValue) => {
  let convertedValue = value;
  let formatVaild = false;
  let referenceVaild = false;
  let isHasValue = false;
  switch( valueType ){
    // 이메일
    case 'email' : {
      convertedValue = value.replace(/^\s+|[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]+|\s+$/g, '');
      formatVaild = isValidEmail(convertedValue)
      break; }
    // 연락처
    case 'tel' : {
      convertedValue = autoHypenPhone(value);
      formatVaild = isValidPhoneNumber(convertedValue)
      break; }
    // 계좌번호
    case 'accountNum' : {
      convertedValue = value.replace(/^\s+|[^0-9]+|\s+$/g, '');
      formatVaild = isValidMaxNumber(convertedValue, 10, 15)
      break; }
    // MFA code
    case 'mfa' : {
      convertedValue = value.replace(/^\s+|[^0-9]+|\s+$/g, '');
      formatVaild = isValidMaxNumber(convertedValue, 6, 6)
      break; }
    // 프로모션코드
    case 'promotionCode' : {
      // convertedValue = value.replace(/^\s+|[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]+|\s+$/g, '');
      convertedValue = value.replace(/^\s+|[^\!ㄱ-ㅎ|가-힣|a-z|A-Z|0-9\s]/g, '');
      formatVaild = isValidPromotionCode(convertedValue)
      break; }
    // 비밀번호
    case 'password' : {
      convertedValue = value.replace(/^\s+|[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]+|\s+$/g, '');
      formatVaild = isValidPassword(convertedValue)
      break; }
    // 한글
    case 'korean' : {
      convertedValue = value.replace(/[0-9]|[^\!ㄱ-ㅎ|ㅏ-ㅣ|가-힣\s]/g, '');
      formatVaild = value != '';
      break; }
    // 영어
    case 'eng' : {
      convertedValue = value.replace(/[0-9]|[^\!-z\s]/gi, '');
      formatVaild = value != '';
      break; }
    // 한글,영어
    case 'koreanEng' : {
      convertedValue = value.replace(/^\s+|[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z]+|\s+$/g, '');
      formatVaild = value != '';
      break; }
    // 한글,영어,숫자
    case 'koreanEngNumber' : {
      convertedValue = value.replace(/^\s+|[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9]+|\s+$/g, '');
      formatVaild = value != '';
      break; }
    // 링크명
    case 'url' : {
      convertedValue = value.replace(/^\s+|[^a-z|A-Z|0-9|\_|\-]+|\s+$/g, '');
      formatVaild = value != '';
      break; }
      // 비밀번호
    case 'password_confirm' : {
      convertedValue = value.replace(/^\s+|[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]+|\s+$/g, '');
      formatVaild = isValidPassword(convertedValue) &&  value == referenceValue;
      break; }
    // 배열
    case 'array' : {
      formatVaild = value && value.length > 0;
      isHasValue = formatVaild;
      break; }
    // 숫자
    case 'number' : {
      convertedValue = Boolean(value != null && String(value)) ? String(value).replace(/[^0-9.]/g, '') : value;
      formatVaild = Boolean(value != null && String(value));
      break; }
  }
  if( typeof(value) == 'number' ){
    isHasValue = value != null && String(value);
  }else if( typeof(value) == 'string' ){
    isHasValue = value != '';
  }
  if(!valueType){ formatVaild = isHasValue; }
  if(referenceValue){
    referenceVaild = value == referenceValue;
    formatVaild = formatVaild && referenceVaild;
  }else if(unReferenceValue){
    referenceVaild = value != unReferenceValue;
    formatVaild = formatVaild && referenceVaild;
  }
  // formatVaild : true - 통과
  return { convertedValue: convertedValue, formatVaild: formatVaild, referenceVaild: referenceVaild, isHasValue: isHasValue }
}

export const pageValidated = (validDataList, model) => {
  let validArray = Object.values(validDataList);
  let isValid = false;
  validArray = validArray.filter(item => (!item.vIf || model[item.vIf.valueName] === item.vIf.value) && (!item.vIfNot || model[item.vIfNot.valueName] != item.vIfNot.value) );
  validArray.forEach( validItem => {
    const value = model[validItem.value];
    const referenceValue = model[validItem.referenceValue];
    const unReferenceValue = model[validItem.unReferenceValue];
    const { formatVaild, isHasValue } = autoValid(value, validItem.valueType, referenceValue, unReferenceValue);
    if( isHasValue ){
      validItem.isValid = !formatVaild;
    }else{
      validItem.isValid = false;
    }
    if( !formatVaild ){
      isValid = true;
    }
  });
  return isValid;
}

export const isValidEmail = (email) => {
	const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return email != '' && email != 'undefined' && regex.test(email)
}

export const isValidPassword = (password) => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
  return password != '' && password != 'undefined' && regex.test(password)
}

export const isValidPromotionCode = (promotionCode) => {
  // const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/
  const regex = new RegExp(`^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]{2,15}$`, 'g')
  return promotionCode != '' && promotionCode != 'undefined' && regex.test(promotionCode)
}


export const autoHypenPhone = (str) => {
  if(str == '' || str == undefined) return;
  str = str.replace(/[^0-9]/g, '');
  var tmp = '';
  tmp = str.replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-")
  return tmp;
  // if( str.length < 4){
  //     return str;
  // }else if(str.slice(0, 2) === '02'){      
  //   tmp += str.substr(0, 2);
  //   tmp += '-';
  //   tmp += str.substr(3, 4);
  //   tmp += '-';
  //   tmp += str.substr(7);
  //   return tmp;
  // }else if(str.length < 7){
  //     tmp += str.substr(0, 3);
  //     tmp += '-';
  //     tmp += str.substr(3);
  //     return tmp;
  // }else if(str.length <= 8){
  //   tmp += str.substr(0, 4);
  //   tmp += '-';
  //   tmp += str.substr(4);
  //   return tmp;
  // }else if(str.length < 11){
  //     tmp += str.substr(0, 3);
  //     tmp += '-';
  //     tmp += str.substr(3, 3);
  //     tmp += '-';
  //     tmp += str.substr(6);
  //     return tmp;
  // }else{              
  //     tmp += str.substr(0, 3);
  //     tmp += '-';
  //     tmp += str.substr(3, 4);
  //     tmp += '-';
  //     tmp += str.substr(7);
  //     return tmp;
  // }
  // return str;
}

export const isValidDate = (date) => {
  if(date === '' || date === undefined || date === null){
    return false;
  }
  const dashRegex = /^\d{2,4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
  if(isNaN(date)){
    return dashRegex.test(date);
  }else{
    const isLengthCheck = date.length === 6 || date.length === 8;
    if(date.length === 6){
      const month = date.slice(2,4);
      const day = date.slice(4,6);
      const isMonthCheck = month > 0 && month < 13;
      const isDayCheck = day > 0 && day < 32;
      return isMonthCheck && isDayCheck
    }else if(date.length === 8){
      const month = date.slice(4,6);
      const day = date.slice(6,8);
      const isMonthCheck = month > 0 && month < 13;
      const isDayCheck = day > 0 && day < 32;
      return isMonthCheck && isDayCheck
    }else{
      return false;
    }
  }
}

export const isValidPhoneNumber = (phoneNum) => {
  const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
  return phoneNum != '' && phoneNum != 'undefined' && regex.test(phoneNum)
}

export const isValidMaxNumber = (number, min, max) => {
  const regex = new RegExp(`^[0-9]{${min},${max}}$`, 'g')
  return regex.test(number)
}

export function exceptNone(data) {
  if (!data || data.length == 0) return data;

  if( Array.isArray(data) ){
    const exceptNoneList = data.filter(item => item != 'NONE')

    return exceptNoneList;
  }else{
    return data == 'NONE' ? '' : data;
  }
}

export function setEmptyDate(date) {
  let result = date;
  if( !date || date === '0001-01-01'){
    result = null;
  }
  return result;
}

export function getEmptyDate(date) {
  let result = date;
  if(date === '' || date === null){
    result = '0001-01-01';
  }
  return result;
}