
// lemontree -> firfin 주소로 리다이렉트.
const isProductionMode = process.env.NODE_ENV === 'production';
const initHost = window.location.host;
const needRedirectHost = [
  'www.lemontree.ai',
  'lemontree.ai',
  'firfin.family',

  'www.preprod.lemontree.ai',
  'preprod.lemontree.ai'
]
const isNeedRedirect = needRedirectHost.indexOf(initHost) > -1;

function redirect() {
  const firfinHost = isProductionMode
    ? 'https://www.firfin.family'
    : 'https://www.dev.firfin.family';
  const toFullPath = window.location.href
    .split(window.location.origin)
    .join(firfinHost)
    + window.location.search;
  window.location.href = toFullPath;
}

if (isNeedRedirect) {
  redirect();

  // 아래로 코드 흐르지 않게.
  throw new Error('Need Redirect');
}
// End lemontree -> firfin 주소로 리다이렉트.

import Vue from 'vue'
import App from './App.vue'
import router from './router/';
import routerPath from './router/routerPath.js';
import store from './store/index';
import apiPath from './services/apiPath';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'
import './filter/index';

Vue.config.productionTip = false;

import Meta from 'vue-meta';
Vue.use(Meta);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
import {Swiper as SwiperClass, Pagination, Navigation, EffectFade} from 'vue-awesome-swiper'
SwiperClass.use([Pagination, Navigation, EffectFade]);

Vue.prototype.$routerPath = routerPath;
Vue.prototype.$apiPath = apiPath;
Vue.prototype.$config = {
  kakaoJSKey : 'b338611c7d7e534cb886cddb015a559e',
  // naverNaId : '160c720a482caf0',
}

const userAgent = navigator.userAgent.toLowerCase() || '';
let varUA;
if ( userAgent.indexOf('android') > -1) {
  varUA = 'ANDROID';
}else if ( userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1 || userAgent.indexOf("mac") > -1 ) {
  varUA = 'IOS';
}else{
  varUA = 'ETC';
}
Vue.prototype.$varUA = varUA;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
